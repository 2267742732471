/* eslint-disable */
import React, { createRef, useEffect, useState, useLayoutEffect } from "react";
import "./App.css";
import { useLocation, useRoutes, useNavigate } from "react-router-dom";
import routes from "./router/router";
import { Container, Navbar, Image, Form, Button } from "react-bootstrap";
// import { NavDropdown, Container, Nav, Navbar, Image, Form, Button, Dropdown } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import TopNavbar from './pages/components/Navbar/Navbar';
import logo from "./static/logo.png"
import { Toaster } from 'react-hot-toast';
import CookieConsent from "react-cookie-consent";

interface User {
	id: number;
	name: string;
	email: string;
	emailVerified: boolean;
	googleEmail: string | null;
	imageUrl: string | null;
	provider: string | null;
	providerId: number | null;
}

function App() {
	const location = useLocation();
	// console.log(location.pathname)
	const headHideList = ['/login', '/welcome', '/app-head', '/docta-ai', '/oauth2/redirect']; // 需要隐藏头部导航的路由页面
	const showNav = headHideList.includes(location.pathname);

	// 用于存储页面使用那种主题导航
	const themeList = [
		{ url: "/", class: "head-dark-theme" },
		{ url: "/safety", class: "Safety" },
		{ url: "/blog", class: "Blog" },
		{ url: "/about", class: "About" },
		{ url: "/product", class: "head-dark-theme" },
		{ url: "/privacy-policy", class: "head-light-theme" },
		{ url: "/Article", class: "Article" },
	];

	// 头部导航主题样式
	const [themeClass, setThemeClass] = useState("head-dark-theme");
	useEffect(() => {
		// 查找有没有对应的头部导航主题，没有默认黑色主题
		const css = themeList.find((item) => item.url === location.pathname);
		if (css) {
			setThemeClass(css.class);
		}
	});

	// 控制搜索弹框的显示和隐藏
	const [showSearch, setShowSearch] = useState(false);
	// 显示搜索
	const handleClose = () => setShowSearch(false);
	// 隐藏搜索
	// const handleShow = () => setShowSearch(true);
	// 路由组件
	const routeElement = useRoutes(routes.routes);
	// 导航Ref
	const nav = createRef<HTMLDivElement>();
	// 根据当前滚动条的位置判断导航条的主题色，此处为导航条的位置state
	const [scrollPosition, setScrollPosition] = React.useState(0);
	// 导航主题样式变量
	let navClass;
	if (scrollPosition > 20) {
		navClass = "bg-black-nav";
	} else {
		navClass = "bg-transparent-nav";
	}
	const handleScroll = () => {
		// 处理滚动事件的逻辑
		console.log(scrollPosition);
		setScrollPosition(window.pageYOffset || document.documentElement.scrollTop);
	};

	const [user, setUser] = useState<User | null>(null);
	user
	const userData = localStorage.getItem('user');
	useEffect(() => {
		if (userData) {
			const parsedUser = JSON.parse(userData) as User;
			setUser(parsedUser);
		} else {
			setUser(null);
		}
	}, [userData]);

	// const LogOut = () => {
	// 	localStorage.removeItem('user')
	// 	localStorage.removeItem('token')
	// 	navigate('/');
	// }

	const navigate = useNavigate();
	{
		/* 路由 */
	}

	// const toDoctaAi = () => {
	// 	// 跳转ai页面，首先判断是否已经登录
	// 	const token = localStorage.getItem("token")
	// 	if (token) {
	// 		navigate('/docta-ai')
	// 	} else {
	// 		// navigate('/docta-ai')

	// 		navigate('/welcome')
	// 	}
	// };

	useLayoutEffect(() => {
		window.scrollTo({ top: 0 });
	}, [navigate]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<Toaster />
			{
				showNav ? (<></>) : (
					<TopNavbar navClass={navClass} themeClass={themeClass} />
				)
			}

			<div className={themeClass}>{routeElement}</div>
			<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>

			<Modal
				className="search-modal"
				show={showSearch}
				onHide={handleClose}
				animation={false}
			>
				<Modal.Header closeButton>
					<Navbar
						collapseOnSelect
						expand={"lg"}
						ref={nav}
						sticky="top"
						className={"nav-pos " + navClass}
					>
						<Container className={"nav-container"}>
							<Navbar.Brand href="#home">
								<Image src={logo} className="logo" />
							</Navbar.Brand>
							<Navbar.Text
								className={"txt-white close-search-btn"}
								onClick={handleClose}
							>
								Close
							</Navbar.Text>
						</Container>
					</Navbar>
				</Modal.Header>
				<Modal.Body></Modal.Body>
				<Modal.Footer>
					<Navbar
						collapseOnSelect
						expand={"lg"}
						className={"nav-pos-bottom " + navClass}
					>
						<Container className={"nav-container"}>
							<InputGroup className="mb-3 search-input" size="lg">
								<Form.Control
									placeholder="Search for anything"
									aria-label="Search for anything"
									aria-describedby="basic-addon2"
								/>
								<Button variant="outline-secondary" id="button-search">
									Search
								</Button>
							</InputGroup>
						</Container>
					</Navbar>
				</Modal.Footer>
			</Modal>

		</>
	);
}

export default App;
