import React from "react";
import {NewsType} from '../../../libs/ENUMS/enum'
import './NewsCard.css'
// import Icon1 from '../../../static/home/news-icon-1.svg'
// import Icon2 from '../../../static/home/news-icon-2.svg'
// import Icon3 from '../../../static/home/news-icon-3.svg'
import Icon1 from '../../../static/home/data-collect.svg'
import Icon2 from '../../../static/home/data-annotation.svg'
import Icon3 from '../../../static/home/data-curation.svg'
/**
 * 新闻卡片
 * @param {object} props 传入属性
 * @constructor
 */
function NewsCard(props: {
		type: NewsType,
		title: string,
		title2: string,
	}) {
	console.log(props)
	// 根据传入类型，构建新闻卡片图标
	const buildIconItem = ()=> {
		switch (props.type) {
			case NewsType.Type_1:
				return <img src={Icon1} alt=""/>;
			case NewsType.Type_2:
				return <img src={Icon2} alt=""/>;
			case NewsType.Type_3:
				return <img src={Icon3} alt=""/>;
			default:
				return <></>
		}
	}
	return (<div className="card-wrapper">
		<div className="icon-wrapper">
			{buildIconItem()}
		</div>
		<div className="card-content">
			<div className="news-title">
				<span>{props.title}</span> <span>{props.title2}</span>
			</div>
			{/* <div className="news-link">
				<a href="#">{props.link || "Link goes here, 1 row max"}</a>
			</div> */}
		</div>
	</div>);
}

export default NewsCard;
