import React from "react";
import './PageSectionTitle.css'

function PageSectionTitle(props: {title?: string}){
	const {title = 'Header Title'} = props;
	return (
		<div className="section-title-wrapper">
			<div className="header-text">{title}</div>
			{/* <div className="header-link">
				<a href="#">View about updates</a>
			</div> */}
		</div>
	)
}

export default PageSectionTitle
