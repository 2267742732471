/* eslint-disable */
// import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import NewsCard from "./components/home/NewsCard";
import PageSectionTitle from "./components/home/PageSectionTitle";
import UpdateCards from "./components/home/UpdateCards";
import SiteFooter from "./components/common/SiteFooter";
import SiteBottomInfo from "./components/common/SiteBottomInfo";
import Introduce from "./components/introduce/Introduce"
import { NewsType } from "../libs/ENUMS/enum";
import "../styles/Home.css";
import { Link } from "react-router-dom";
import '../styles/Home.css'
import centralImg from '../static/home/img1.jpg'
import centralImg1 from '../static/home/news-item-wrapper1.png'
import centralImg2 from '../static/home/news-item-wrapper2.png'
import logo from "../static/logo.png"

function Home() {
	const navigate = useNavigate()

	const [newItemImage, setNewItemImage] = useState(1)

	const handleMouseEnter = (index: number) => {
		setNewItemImage(index)
	}

	const [text] = useState(`
		Use the Text web part to add paragraphs to your page.
		Formatting options like styles, bullets, indentations,
		highlighting, and links are available. Indent text with spaces.
	`)

	const toDoctaAi = () => {
		// 跳转ai页面，首先判断是否已经登录
		const token = localStorage.getItem("token")
		if (token) {
			navigate('/docta-ai')
		} else {
			// navigate('/docta-ai')

			navigate('/welcome')
		}
	};

	return (
		<div className="page">
			<header className="App-header flex-column">
				<div className="hero-sec">
					<div className="hero-pos-show">
						<Image src={logo} className="hero-logo" />
						<div>
							<div className="hero-pos-show-text">A Doctor for your Data.</div>
							<div className="hero-pos-show-text">
								Provide high-quality data for AI models.
							</div>
							<div className="hero-pos-show-btn">
								<div className="hero-pos-show-btn-item">
									<Link
										to="https://github.com/Docta-ai/docta"
										style={{ textDecoration: "none", color: "white" }}
									>
										OPEN SOURCE
									</Link>
								</div>
								<div className="hero-pos-show-btn-item">
									<Link
										to="https://forms.gle/YW3uhMQ1ksyDqPzM9"
										style={{ textDecoration: "none", color: "white" }}
									>
										REQUEST A DEMO
									</Link>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="hero-pos-bottom">
						<h1 className="hero-text">
							Creating safe AGI that benefits all of humanity
						</h1>
						<button className="hero-learn-btn" onClick={()=> {
							navigate("/about")
						}}>Learn about DoctaAI</button>
					</div> */}
				</div>
				{/*News 模块*/}
				<div className="news-item-wrapper">
					<Row className="row-fill">
						<Col xs={12} md={4} onMouseEnter={() => handleMouseEnter(1)}>
							<NewsCard
								type={NewsType.Type_1}
								title="Efficient"
								title2="data collection"
							></NewsCard>
						</Col>
						<Col xs={12} md={4} onMouseEnter={() => handleMouseEnter(2)}>
							<NewsCard
								type={NewsType.Type_2}
								title="Automated"
								title2="data annotation"
							></NewsCard>
						</Col>
						<Col xs={12} md={4} onMouseEnter={() => handleMouseEnter(3)}>
							<NewsCard
								type={NewsType.Type_3}
								title="Reliable"
								title2="data curation"
							></NewsCard>
						</Col>
					</Row>
				</div>
				{/* <div className="item-new-class">
					<div className="item-new-class-image" style={ newItemImage == 1 ? { 'opacity': 1 } : {}}>
						<Introduce
							title="Header title1"
							assistantTitle={text}
							img={centralImg1}
							color="#fff"
							align="left"
						/>
					</div>
					<div className="item-new-class-image positioning" style={ newItemImage == 2 ? { 'opacity': 1 } : {}}>
						<Introduce
							title="Header title2"
							assistantTitle={text}
							img={centralImg2}
							color="#fff"
							align="left"
						/>
					</div>
					<div className="item-new-class-image positioning" style={ newItemImage == 3 ? { 'opacity': 1 } : {}}>
						<Introduce
							title="Header title3"
							assistantTitle={text}
							img={centralImg1}
							color="#fff"
							align="left"
						/>
					</div>
				</div> */}
				{/*这里似乎要填写点有用的东西*/}
				<br />
				<br />
				<video className="central-img" controls autoPlay muted>
					<source src="unvideo/video-3min.mp4" type="video/mp4"/>
				</video>
				<PageSectionTitle title={'Recent Updates'} />
				<UpdateCards type="community-outreach"></UpdateCards>
				
				<PageSectionTitle title={'Technical Blogs'} />
				<UpdateCards type="tech-blog"></UpdateCards>

				<PageSectionTitle title={'Customer Success Stories'} />
				<UpdateCards type="use-case"></UpdateCards>
				{/*这里似乎要填写点有用的东西，目前设计稿上是security，但是这里客户方也没提其他的要求*/}
				{/* <PageSectionTitle /> */}
				{/* 替换成联合国视频。 */}
				{/* <img src={centralImg} alt="" className="central-img" />  */}

				{/*这里似乎要填写点有用的东西*/}
				{/* <PageSectionTitle />
				<UpdateCards></UpdateCards> */}
				{/*就是个104px得间隙*/}
				<div className="col-space"></div>
				{/* <SiteFooter></SiteFooter> */}
				<SiteBottomInfo theme="dark-theme" />
			</header>
		</div>
	);
}

export default Home;
