import React from "react";
import "./SiteBottomInfo.css";
// import { useNavigate } from "react-router-dom";

import upward from "../../../static/home/upward.svg";
import darkUpward from "../../../static/home/dark_upward.svg";

import { Image } from "react-bootstrap";

{
	/* theme表示白色或者黑色主题 light-theme 白色主题  dark-theme 黑色主题 */
}
interface MyComponentProps {
	theme: string;
}

const SiteBottomInfo: React.FC<MyComponentProps> = (props) => {
	const handleClick = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	// const navigate = useNavigate();
	{
		/* 路由 */
	}

	// const toDoctaAi = (url: string) => {
	// 	// 跳转ai页面，首先判断是否已经登录
	// 	return
	// 	navigate(url)
	// };

	return (
		<div className={"SiteBottomInfo " + props.theme}>
			<div className="SiteBottomInfo-conter">
				<div className="SiteBottomInfo-item">
					<div>Docta.ai @ 2023-2024</div>
					<a
						href="mailto:contact@docta.ai"
						style={{ color: "inherit", textDecoration: "none" }}
					>
						Contact Us
					</a>
					<div>
						<a
							href="/privacy-policy"
							style={{ color: "inherit", textDecoration: "none" }}
						>
							Privacy policy
						</a>
					</div>
					<div>
						<a
							href="/term-of-use"
							style={{ color: "inherit", textDecoration: "none" }}
						>
							Term of use
						</a>
					</div>
					<div>
						<a
							href="/service-terms"
							style={{ color: "inherit", textDecoration: "none" }}
						>
							Service terms
						</a>
					</div>
					{/* <div>Brand guidelines</div> */}
				</div>
				<div className="SiteBottomInfo-item">
					{/* <div className="SiteBottomInfo-item-flex">
						<div style={{ marginRight: '100px' }}>Item</div>
						<div>Item</div>
					</div> */}
					<div className="back-top-btn" onClick={handleClick}>
						Back to top
						<Image src={props.theme == "dark-theme" ? upward : darkUpward} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SiteBottomInfo;
