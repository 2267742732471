/* eslint-disable max-lines */
import React from "react";
import "../styles/PrivacyPolicy.css";

import SiteBottomInfo from "./components/common/SiteBottomInfo";

function PrivacyPolicy() {
	return (
		<div className="App">
			<header className="privacy flex-column">
				<div className="privacy-top">
					<span>Privacy policy</span>
				</div>

				<div className="privacy-conter">
					{/* <div className="privacy-conter-time"></div> */}
					<div className="privacy-conter-text">
						{/* <div className="privacy-top-title">Effective: January 31, 2024</div> */}

						<div className="privacy-conter-paragraph">
							We respect your privacy and are strongly committed to keeping
							secure any information we obtain from you or about you. This
							Privacy Policy describes our practices with respect to Personal
							Information we collect from or about you when you use our website,
							applications, and services (collectively, “Services”). This
							Privacy Policy does not apply to content that we process on behalf
							of customers of our business offerings, such as our API. Our use
							of that data is governed by our customer agreements covering
							access to and use of those offerings.
						</div>

						<div className="privacy-conter-text-title">
							Personal information we collect
						</div>
						<div className="privacy-conter-paragraph">
							We may collect personal information relating to you (“Personal
							Information”) as follows:
						</div>
						<ul className="privacy-ul">
							<b>Personal information you provide:</b>
							<li>
								<b>Account Information:</b> When you create an account with us,
								we will collect information associated with your account,
								including your name, contact information, account credentials,
								payment card information, and transaction history.{" "}
							</li>
							<li>
								<b>User Content:</b> When you use our Services, we collect
								Personal Information that is included in the input, file
								uploads, or feedback that you provide to our Services.
							</li>
							<li>
								<b>Communication Information:</b> If you communicate with us, we
								collect your name, contact information, and the contents of any
								messages you send.
							</li>
							<li>
								<b>Social Media Information:</b> When you interact with our
								social media pages, we collect Personal Information that you
								elect to provide to us, such as your contact details. The
								companies that host our social media pages may provide us with
								aggregate information and analytics about our social media
								activity.
							</li>
							<li>
								<b>Other Information You Provide:</b> We collect other
								information that you may provide to us, such as when you
								participate in our events or surveys or provide us with
								information to establish your identity.
							</li>
						</ul>

						<br />

						<ul className="privacy-ul">
							<b>
								Personal information we receive automatically from your use of
								the Services:{" "}
							</b>
							<li>
								<b>Log Data:</b> Information that your browser or device
								automatically sends when you use our Services, including your IP
								address, browser type and settings, the date and time of your
								request, and how you interact with our Services.
							</li>
							<li>
								<b>Usage Data:</b> Information about your use of the Services,
								such as the types of content you view or engage with, the
								features you use, and the actions you take.
							</li>
							<li>
								<b>Device Information:</b> Information about your device,
								operating system, device identifiers, and browser you are using.
							</li>
							<li>
								<b>Cookies:</b> We use cookies to operate and administer our
								Services and improve your experience. You can set your browser
								to accept or reject cookies, or to notify you whenever a cookie
								is offered so that you can decide each time whether to accept
								it.
							</li>
							<li>
								<b>Analytics:</b> We may use online analytics products that use
								cookies to help us analyze how users use our Services and
								enhance your experience when you use the Services.
							</li>
						</ul>

						<div className="privacy-conter-text-title">
							How We Use Personal Information
						</div>
						<div className="privacy-conter-paragraph">
							We may use Personal Information for the following purposes:{" "}
						</div>
						<ul className="privacy-ul">
							<li>
								To provide, administer, maintain, and analyze the Services;
							</li>
							<li>To improve our Services and conduct research;</li>
							<li>
								To communicate with you, including to send you information about
								our Services and events;
							</li>
							<li>To develop new programs and services;</li>
							<li>
								To prevent fraud, criminal activity, or misuse of our Services,
								and to protect the security of our IT systems, architecture, and
								networks;
							</li>
							<li>To carry out business transfers; and</li>
							<li>
								To comply with legal obligations and legal processes and to
								protect our rights, privacy, safety, or property, and/or that of
								our affiliates, you, or other third parties.
							</li>
						</ul>
						<div className="privacy-conter-paragraph">
							<b>Aggregated or de-identified information:</b> We may aggregate
							or de-identify Personal Information so that it may no longer be
							used to identify you and use such information to analyze the
							effectiveness of our Services, improve and add features, conduct
							research, and for other similar purposes. We will maintain and use
							de-identified information in anonymous or de-identified form and
							will not attempt to reidentify the information, unless required by
							law.
						</div>
						<br />
						<div className="privacy-conter-paragraph">
							As noted above, we may use Content you provide us to improve our
							Services, for example to train the models. You can opt out of our
							use of your Content to train our models by following the
							instructions in our help center.
						</div>

						<div className="privacy-conter-text-title">
							Disclosure of Personal Information
						</div>
						<div className="privacy-conter-paragraph">
							In certain circumstances, we may provide your Personal Information
							to third parties without further notice to you, unless required by
							the law:{" "}
						</div>
						<ul className="privacy-ul">
							<li>
								<b>Vendors and Service Providers:</b> To assist us in meeting
								business operations needs and performing certain services and
								functions, we may provide Personal Information to vendors and
								service providers, including providers of hosting services,
								customer service vendors, cloud services, email communication
								software, web analytics services, and other IT providers. These
								parties will access, process, or store Personal Information only
								in the course of performing their duties to us.
							</li>
							<li>
								<b>Business Transfers:</b> If we are involved in strategic
								transactions, reorganization, bankruptcy, receivership, or
								transition of service to another provider, your Personal
								Information and other information may be disclosed in the
								diligence process with counterparties and others assisting with
								the transaction and transferred to a successor or affiliate as
								part of that transaction.
							</li>
							<li>
								<b>Legal Requirements:</b> We may share your Personal
								Information with government authorities, industry peers, or
								other third parties if required to do so by law, to protect our
								rights or property, to detect or prevent fraud or other illegal
								activity, to protect the safety, security, and integrity of our
								products, employees, or users, or the public, or to protect
								against legal liability.
							</li>
							<li>
								<b>Affiliates:</b> We may disclose Personal Information to our
								affiliates, meaning an entity that controls, is controlled by,
								or is under common control with us. Our affiliates may use the
								Personal Information we share in a manner consistent with this
								Privacy Policy.
							</li>
							<li>
								<b>Business Account Administrators:</b> When you join an
								enterprise or business account, the administrators of that
								account may access and control your account. If you create an
								account using an email address belonging to your employer or
								another organization, we may share the fact that you have an
								account and certain account information, such as your email
								address, with your employer or organization.
							</li>
							<li>
								<b>Other Users and Third Parties You Share Information With:</b>
								Certain features allow you to display or share information with
								other users or third parties. Be sure you trust any user or
								third party with whom you share information.
							</li>
						</ul>

						<div className="privacy-conter-text-title">Your Rights</div>
						<div className="privacy-conter-paragraph">
							Depending on your location, you may have certain statutory rights
							in relation to your Personal Information. For example, you may
							have the right to:
						</div>
						<ul className="privacy-ul">
							<li>
								Access your Personal Information and information relating to how
								it is processed.
							</li>
							<li>Delete your Personal Information from our records.</li>
							<li>Rectify or update your Personal Information.</li>
							<li>
								Transfer your Personal Information to a third party (right to
								data portability).
							</li>
							<li>Restrict how we process your Personal Information.</li>
							<li>
								Withdraw your consent, where we rely on consent as the legal
								basis for processing at any time.
							</li>
							<li>Object to how we process your Personal Information.</li>
							<li>
								Lodge a complaint with your local data protection authority.
							</li>
						</ul>
						<div className="privacy-conter-paragraph">
							To exercise any of these rights, please contact us at
							contact@docta.ai
						</div>

						<div className="privacy-conter-text-title">
							Additional U.S. State Disclosures
						</div>
						<div className="privacy-conter-paragraph">
							Depending on local law, you may have the right to:
						</div>
						<ul className="privacy-ul">
							<li>
								Know information about our processing of your Personal
								Information.
							</li>
							<li>Request deletion of your Personal Information.</li>
							<li>Correct your Personal Information.</li>
							<li>
								Be free from discrimination relating to the exercise of any of
								your privacy rights.
							</li>
						</ul>
						<div className="privacy-conter-paragraph">
							We don’t “sell” Personal Information or “share” Personal
							Information for cross-contextual behavioral advertising. We also
							don’t process sensitive Personal Information for the purposes of
							inferring characteristics about a consumer.{" "}
						</div>

						<div className="privacy-conter-paragraph">
							<b>Exercising your rights:</b> To the extent applicable under
							local law, you can exercise privacy rights described in this
							section by submitting a request through our privacy portal or
							emailing us.{" "}
						</div>

						<div className="privacy-conter-paragraph">
							<b>Verification:</b> To protect your Personal Information from
							unauthorized access, change, or deletion, we may require you to
							verify your credentials before you can submit a request. If you do
							not have an account with us, or if we suspect fraudulent or
							malicious activity, we may ask you to provide additional Personal
							Information and proof of residency for verification.
						</div>
						<div className="privacy-conter-paragraph">
							<b>Authorized agents:</b> You may submit a rights request through
							an authorized agent. The agent must present signed written
							permission to act on your behalf, and you may also be required to
							independently verify your identity and submit proof of your
							residency.
						</div>

						<div className="privacy-conter-paragraph">
							<b>Appeals:</b> Depending on where you live, you may have the
							right to appeal a decision we make relating to requests to
							exercise your rights. To appeal a decision, please email us.{" "}
						</div>

						<div className="privacy-conter-text-title">Children</div>
						<div className="privacy-conter-paragraph">
							Our Service is not directed to children under the age of 13. We do
							not knowingly collect Personal Information from children under the
							age of 13. If you believe a child under the age of 13 has provided
							Personal Information to us, please email us, and we will delete
							the Personal Information from our systems. If you are 13 or older
							but under 18, you must have permission from your parent or
							guardian to use our Services.
						</div>

						<div className="privacy-conter-text-title">
							Links to Other Websites
						</div>
						<div className="privacy-conter-paragraph">
							The Service may contain links to other websites not operated or
							controlled by us, including social media services (“Third Party
							Sites”). The information you share with Third Party Sites will be
							governed by their privacy policies and terms of service, not this
							Privacy Policy. By providing these links, we do not endorse or
							review these sites. Please contact the Third Party Sites directly
							for information on their privacy practices.
						</div>

						<div className="privacy-conter-text-title">
							Security and Retention
						</div>
						<div className="privacy-conter-paragraph">
							We implement commercially reasonable technical, administrative,
							and organizational measures to protect Personal Information from
							loss, misuse, and unauthorized access, disclosure, alteration, or
							destruction. However, no Internet or email transmission is ever
							fully secure or error-free. Therefore, you should take special
							care in deciding what information you send to us via the Service
							or email. We are not responsible for circumvention of any privacy
							settings or security measures contained on the Service or
							third-party websites.{" "}
						</div>
						<div className="privacy-conter-paragraph">
							We will retain your Personal Information only as long as necessary
							to provide our Service to you, or for other legitimate business
							purposes such as resolving disputes, safety and security reasons,
							or complying with legal obligations.
						</div>

						<div className="privacy-conter-text-title">International Users</div>
						<div className="privacy-conter-paragraph">
							By using our Service, you understand and acknowledge that your
							Personal Information will be processed and stored in our
							facilities and servers in the United States and may be disclosed
							to our service providers and affiliates in other jurisdictions.{" "}
						</div>
						<ul className="privacy-ul">
							<div className="privacy-conter-paragraph">
								<b>Legal basis for processing:</b> Our legal bases for
								processing your Personal Information include:
							</div>
							<li>
								<b>Performance of a contract:</b> When we provide and maintain
								our Services, processing Account Information, Content, and
								Technical Information is necessary. Without this information, we
								may not be able to provide our Services to you.
							</li>
							<li>
								<b>Legitimate interests:</b> Protecting our Services from abuse,
								fraud, or security risks, or developing, improving, or promoting
								our Services. This may include processing Account Information,
								Content, Social Information, and Technical Information.
							</li>
							<li>
								<b>Your consent:</b> When we ask for your consent to process
								your Personal Information for a specific purpose. You have the
								right to withdraw your consent at any time.
							</li>
							<li>
								<b>Compliance with legal obligations:</b> When we use your
								Personal Information to comply with applicable law or to protect
								our or our affiliates’, users’, or third parties’ rights,
								safety, and property.
							</li>
							<div className="privacy-conter-paragraph">
								<b>Data transfers:</b> Where required, we will use appropriate
								safeguards for transferring Personal Information outside of
								certain countries. We will only transfer Personal Information
								pursuant to a legally valid transfer mechanism.
							</div>
						</ul>

						<div className="privacy-conter-text-title">
							Changes to the Privacy Policy
						</div>
						<div className="privacy-conter-paragraph">
							We may update this Privacy Policy from time to time. When we do,
							we will post an updated version on this page, unless another type
							of notice is required by applicable law.
						</div>

						<div className="privacy-conter-text-title">Contact Us</div>
						<div className="privacy-conter-paragraph">
							If you have any questions about this Privacy Policy, please
							contact us at contact@docta.ai
						</div>
					</div>
				</div>

				<SiteBottomInfo theme="light-theme" />
			</header>
		</div>
	);
}

export default PrivacyPolicy;
