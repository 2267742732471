/* eslint-disable max-lines */
import React from "react";
import "../styles/PrivacyPolicy.css";

import SiteBottomInfo from "./components/common/SiteBottomInfo";

function SerivceTerms() {
	return (
		<div className="App">
			<header className="privacy flex-column">
				<div className="privacy-top">
					<span>Privacy policy</span>
				</div>

				<div className="privacy-conter">
					{/* <div className="privacy-conter-time"></div> */}
					<div className="privacy-conter-text">
						{/* <div className="privacy-top-title">Effective: January 31, 2024</div> */}

						<div className="privacy-conter-paragraph">
							These Service Terms govern your use of the Services. Capitalized
							terms not defined here will have the meanings in the Terms of Use
							or other agreements you have with us governing your use of the
							Services (“Agreement”). If there is a conflict between these
							Service Terms and your Agreement, these Service Terms will
							control. For purposes of these Terms, “Content” includes “Customer
							Content.”
						</div>

						<div className="privacy-conter-text-title">API</div>
						<div className="privacy-conter-paragraph">
							Our indemnification obligations to API customers under the
							Agreement cover any third-party claim that the Customer’s use or
							distribution of Output infringes a third party’s intellectual
							property rights. This indemnity does not apply where: (i) Customer
							or Customer’s End Users knew or should have known the Output was
							infringing or likely to infringe, (ii) Customer or Customer’s End
							Users disabled, ignored, or did not use any relevant citation,
							filtering, or safety features or restrictions provided by us,
							(iii) Output was modified, transformed, or used in combination
							with products or services not provided by or on our behalf, (iv)
							Customer or its End Users did not have the right to use the Input
							or fine-tuning files to generate the allegedly infringing Output,
							(v) the claim alleges violation of trademark or related rights
							based on Customer’s or its End Users’ use of Output in trade or
							commerce, and (vi) the allegedly infringing Output is from content
							from a Third Party Offering.
						</div>

						<div className="privacy-conter-text-title">Beta Services </div>
						<div className="privacy-conter-paragraph">
							This section governs your use of services or features that we
							offer on an alpha, preview, early access, or beta basis (“Beta
							Services”). Beta Services are offered “as-is” to allow testing and
							evaluation and are excluded from any indemnification obligations
							we may have to you.
						</div>

						<div className="privacy-conter-paragraph">
							We make no representations or warranties for Beta Services,
							including any warranty that Beta Services will be generally
							available, uninterrupted, or error-free, or that Content will be
							secure or not lost or damaged. Except to the extent prohibited by
							law, we expressly disclaim all warranties for Beta Services,
							including any implied warranties of merchantability, satisfactory
							quality, fitness for a particular purpose, non-infringement, or
							quiet enjoyment, and any warranties arising out of any course of
							dealing or usage of trade.
						</div>

						<div className="privacy-conter-text-title">Code Generation</div>
						<div className="privacy-conter-paragraph">
							Output generated by code generation features of our Services may
							be subject to third party licenses, including, without limitation,
							open source licenses.
						</div>

						<div className="privacy-conter-text-title">Image Capabilities</div>
						<div className="privacy-conter-paragraph">
							Our models may accept images as part of Inputs to the Service
							(“Image Capabilities”).
						</div>
						<ul className="privacy-ul">
							<li>
								<b>Not for Medical Advice.</b> Image Capabilities are not
								designed or intended to be used as a medical device or to
								perform any medical function and should not be used as a
								substitute for professional medical advice, diagnosis,
								treatment, or judgment.
							</li>
							<li>
								<b>Images with People.</b> You may not use Image Capabilities to
								assist in identifying a person or to solicit or infer private or
								sensitive information about a person.
							</li>
						</ul>
					</div>
				</div>

				<SiteBottomInfo theme="light-theme" />
			</header>
		</div>
	);
}

export default SerivceTerms;
