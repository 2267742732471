/* eslint-disable */
import React, { useState, useEffect } from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import katex from "katex";
import { getCodeString } from "rehype-rewrite";
import "katex/dist/katex.css";
import "../../../styles/github-markdown-css/github-markdown-light.css";
import { useSearchParams } from "react-router-dom";
import NotFound from "../../NotFound";
// import ReactMarkdown from "react-markdown";
// import gfm from 'remark-gfm'

const NormalTest = (props: {id: string}) => {
	// TODO: 这里的文件名称如README.md需要当作组件的参数传入
	const articles:{ [key: string]: any } = {
		"esci": require("../../../libs/md/esci.md"),
		"noisy-label": require("../../../libs/md/Sec1_Intro_revised.md"),
		"detect-gpt": require("../../../libs/md/detect_gpt.md"),
		"arbitrary_annotations": require("../../../libs/md/arbitrary_annotations.md"),
		"unlife": require("../../../libs/md/unlife.md"),
		"ijcai-tutorial": require("../../../libs/md/ijcai_tutorial.md"),
		"open-source": require("../../../libs/md/open_source.md"),
		"iclr-2024": require("../../../libs/md/iclr2024.md"),
		"image-search": require("../../../libs/md/image_search_and_labeling.md"),
		"language-safety": require("../../../libs/md/language_safety.md"),
		"preference-data-cleaning": require("../../../libs/md/preference-data-cleaning.md"),
	};
	const [currentArticle, setCurrentArticle] = useState<{
		url: string;
		content: string;
	}>({ url: "", content: "" });
	const [searchParams, setSearchParams] = useSearchParams();
	const param = searchParams.get("id");
	if (!param) {
		console.error("No id found in url")
		return <NotFound />
	}
	if (!articles[param]) {
		console.error("No article found for id: " + param)
		return <NotFound />
	}
	console.log(param);

	// 初始为开发文档
	useEffect(() => {
		changeCurrentArticle(articles[param]);
	}, [param]);

	// 更改当前文档
	const changeCurrentArticle = async (url: string) => {
		const res = await fetch(url);
		const content = await res.text();
		console.log(res);
		setCurrentArticle({ ...currentArticle, content, url });
	};

	return (
		<>
			<MarkdownPreview
				components={{
					code: ({ children = [], className, ...props }) => {
						console.log(children);
						if (
							typeof children === "string" &&
							/^\$\$(.*)\$\$/.test(children)
						) {
							const html = katex.renderToString(
								children.replace(/^\$\$(.*)\$\$/, "$1"),
								{
									throwOnError: false,
								}
							);
							return (
								<code
									dangerouslySetInnerHTML={{ __html: html }}
									style={{ background: "transparent" }}
								/>
							);
						}
						// eslint-disable-next-line react/prop-types
						const code =
							props.node && props.node.children
								? getCodeString(props.node.children)
								: children;
						if (
							typeof code === "string" &&
							typeof className === "string" &&
							/^language-katex/.test(className.toLocaleLowerCase())
						) {
							const html = katex.renderToString(code, {
								throwOnError: false,
							});
							return (
								<code
									style={{ fontSize: "150%" }}
									dangerouslySetInnerHTML={{ __html: html }}
								/>
							);
						}
						return <code className={String(className)}>{children}</code>;
					},
				}}
				source={currentArticle.content}
			></MarkdownPreview>
		</>
	);
};
export default NormalTest;
