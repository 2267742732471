/* eslint-disable */
import React, {useState} from "react";
import '../styles/Article.css'

import Introduce from "./components/introduce/Introduce"
import ArticleMd from './components/md/Md'
import {useSearchParams} from "react-router-dom";
import SiteBottomInfo from "./components/common/SiteBottomInfo";
// import blog1 from "../static/blog/blog_1.png"
import blog1 from "../static/blog/blog_1.png"


function Article() {
	const [text] = useState(`
		Use the Text web part to add paragraphs to your page.
		Formatting options like styles, bullets, indentations,
		highlighting, and links are available. Indent text with spaces.
	`)
	const [searchParams] = useSearchParams();

	const id = searchParams.get('id') || 'esci';

	return (
		<div className="App">
			{/* <div className="about-banner">
				<Introduce title="Header title" assistantTitle={text} img={blog1} color="#60127C" align="right" />
			</div>
			<div className="article-bottom-label">
				<div className="article-bottom-label-time">January 10, 2024</div>
				<div className="article-bottom-label-item">Label</div>
				<div className="article-bottom-label-item">Label</div>
			</div> */}
			<div className="md-content">
				<ArticleMd id={id}></ArticleMd>
			</div>
			<SiteBottomInfo theme="light-theme" />
		</div>
	)
}

export default Article;
