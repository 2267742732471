import React from "react";

function NotFound() {
	return (
		<div className="App">
			<header className="App-header">
				404 Not Found
			</header>
		</div>
	);
}

export default NotFound;
