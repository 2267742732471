import React, { createRef } from 'react';
import { Container, Navbar, Image, Nav } from "react-bootstrap";

import logo from "../../../static/logo.png"

function TopNavber(props: { navClass: string, themeClass: string }) {
	// 导航Ref
	const nav = createRef<HTMLDivElement>();

	return (
		<Navbar
			collapseOnSelect
			expand={'lg'}
			ref={nav}
			sticky="top"
			className={`nav-pos ${props.navClass} ${props.themeClass}`}>
			<Container className={"nav-container"}>
				<Navbar.Brand href="/">
					<Image src={logo} className="logo" />
				</Navbar.Brand>
				{/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"txt-white"}>
					Menu
				</Navbar.Toggle> */}
				<Navbar.Collapse id="responsive-navbar-nav">
					{/* <Nav className="me-auto">
						<NavDropdown title="Research" id="collapsible-nav-dropdown" className={navClass}>
							<NavDropdown.Item href="#">Override</NavDropdown.Item>
							<NavDropdown.Item href="#">
								Index
							</NavDropdown.Item>
							<NavDropdown.Item href="#">GPT-4</NavDropdown.Item>
							<NavDropdown.Item href="#">
								DALL·E 3
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href="safety" className={"nav-item"}>Safety</Nav.Link>
						<NavDropdown title="Company" id="collapsible-nav-dropdown" className={navClass}>
							<NavDropdown.Item href="about">About</NavDropdown.Item>
							<NavDropdown.Item href="blog">Blog</NavDropdown.Item>
							<NavDropdown.Item href="Product">Product</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.1">Careers</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.1">Residency</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.1">Charter</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.1">Security</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.1">Customer stories</NavDropdown.Item>
						</NavDropdown>
					</Nav> */}
					{/* <Nav>
						<Nav.Link className={"nav-item"} onClick={handleShow}>Search</Nav.Link>
						{
							user?.id ?
								(
									<Dropdown className={'d-inline mx-2 app-head-dropdown ' + navClass}>
										<Dropdown.Toggle id="dropdown-autoclose-true">
											<div className="app-head-user">
												<div className="app-head-user-img"></div>
												{user?.name || 'Unnamed'}
											</div>
										</Dropdown.Toggle>

										<Dropdown.Menu className="app-head-user-dropdown">
											<Dropdown.Item onClick={() => { LogOut() }}>Login Out</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								) :
								(<Nav.Link className={"nav-item"} eventKey={2} href="welcome">Login</Nav.Link>)
						}
						<Nav.Link className={"nav-item border-whites"} eventKey={2} onClick={toDoctaAi}>Try DoctaAI</Nav.Link>
					</Nav> */}
					<Nav className="ms-auto">
						<Nav.Link href="mailto:contact@docta.ai">Contact Us</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default TopNavber;
