import React, { useEffect, useState, useRef } from "react";
import "./UpdateCards.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
// import placeHolderImage from "../../../static/home/list-item.jpg";
import ESCIImg from "../../../static/blog/esci.jpg";
import detectGPTImg from "../../../static/blog/detect-gpt.jpg";
import NoisyLabelImg from "../../../static/blog/noisylabel.jpg";
import TutorialImg from "../../../static/blog/tutorial.jpg";
import unlifeImg from "../../../static/blog/unlife.png";
import ijcai2023Img from "../../../static/blog/ijcai2023.png";
import openSourceImg from "../../../static/blog/open-source.png";
import iclrImg from "../../../static/blog/iclr2024.jpg";
import aiSafetyImg from "../../../static/blog/AI-safety.png";
import imageSearchImg from "../../../static/blog/Image_search.png";
import preferenceCleaningImg from "../../../static/blog/preference-clean.png";
// import ListItemImg from '../../../static/home/list-item.jpg'
import switchLeft from '../../../static/icon/switch_left.svg'
import switchRight from '../../../static/icon/switch_right.svg'
// import ListItemImg1 from '../../../static/logo.png'

interface UpdateCardsDataType {
	key: string;
	text: string;
	image: string;
	date?: string;
}

function UpdateCards(props: { type: string }) {
	const navigate = useNavigate(); {/* 路由 */ }

	const scrollRef = useRef<HTMLDivElement>(null);

	const handleScroll = (type: string) => {
		if (scrollRef.current) {
			const width = scrollRef.current.offsetWidth; // 获取元素的宽度
			// 假设你想要让页面的 x 轴滚动条滚动到对应宽度
			if (type == 'left') {
				scrollRef.current.scrollLeft -= width
			} else {
				scrollRef.current.scrollLeft += width
			}
		}
	};

	const toMdPage = (id: string) => {
		navigate("/Article?id=" + id);
	};
	const [dataList, setDataList] = useState<UpdateCardsDataType[]>([]);

	// TODO: 这里填写对应路径的文件就行了
	useEffect(() => {
		let data: React.SetStateAction<UpdateCardsDataType[]> = [];
		if (props.type === "tech-blog") {
			data = [
				{
					key: "esci",
					text: "Annotating Product Search Relevance",
					image: ESCIImg,
				},
				{
					key: "detect-gpt",
					text: "Detecting Machine-Generated Content",
					image: detectGPTImg,
				},
				{
					key: "noisy-label",
					text: "Introduction to Noisy Labels",
					image: NoisyLabelImg,
				},
				{
					key: "arbitrary_annotations",
					text: "Detecting the Accuracy of Arbitrary Annotations in Text Data",
					image: TutorialImg,
				},
				// {
				// 	key: "",
				// 	text: "placeholder",
				// 	image: placeHolderImage,
				// },
				// {
				// 	key: "",
				// 	text: "placeholder",
				// 	image: placeHolderImage,
				// },
				// {
				// 	key: "",
				// 	text: "placeholder",
				// 	image: placeHolderImage,
				// },
				// ensure keys are unique
			];
		} else if (props.type === "community-outreach") {
			data = [
				{
					key: "unlife",
					text: "Docta.ai at UNITLIFE, an United Nation Fund",
					image: unlifeImg,
				},
				{
					key: "iclr-2024",
					text: "Docta.ai at ICLR 2024",
					image: iclrImg,
				},
				{
					key: "ijcai-tutorial",
					text: "Docta.ai at IJCAI 2023",
					image: ijcai2023Img,
				},
				{
					key: "open-source",
					text: "Docta.ai's Open Source Version",
					image: openSourceImg,
				},
			];
		} else if (props.type === "use-case") {
			data = [
				{
					key: "image-search",
					text: "A New Era of Image Search and Labeling",
					image: imageSearchImg,
				},
				{
					key: "language-safety",
					text: "The Next-Generation API for Harmful Language Classification and Prediction",
					image: aiSafetyImg,
				},
				{
					key: "preference-data-cleaning",
					text: "Data Cleaning for Preference Data",
					image: preferenceCleaningImg,
				},
			];
		} else {
			console.error("UpdateCard type unknown");
		}
		setDataList(data);
	}, [props.type]);

	// return (
	// 	<div className="update-wrapper scroll-bar-style-x">
	// 		<Row className="list-row">
	// 			{dataList.map((item) => (
	// 				<Col
	// 					xs={6}
	// 					md={3}
	// 					lg={3}
	// 					onClick={() => {
	// 						toMdPage(item.key);
	// 					}}
	// 					key={item.key}
	// 				>
	// 					<img className="item-img" src={item.image} alt="" />
	// 					<div className="item-desc">{item.text}</div>
	// 					{/* <div className="item-date">Jan 14 2024</div> */}
	// 				</Col>
	// 			))}
	// 		</Row>
	return (
		<div className="update-wrapper">
			<img src={switchLeft} className="swithc_btn" onClick={() => {handleScroll('left')}} />
			<img src={switchRight} className="swithc_btn swithc_btn_right" onClick={() => {handleScroll('right')}} />
			<div ref={scrollRef} className="update-wrapper-scroll scroll-bar-style-x">
				<Row className="list-row">
					{dataList.map((item) => (
						<Col xs={6} md={3} lg={3} onClick={() => { toMdPage(item.key) }} key={item.key}>
							<img className="item-img" src={item.image} alt="" />
							<div className="item-desc">
								{item.text}
							</div>
							{/* <div className="item-date">
								Jan 14 2024
							</div> */}
						</Col>
					))}
				</Row>
			</div>
		</div>
	);
}

export default UpdateCards;
