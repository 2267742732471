/* eslint-disable */
import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

// 引入组件
// import MainLayout from '../pages/layouts/MainLayout'
import Home from '../pages/Home'
import About from '../pages/About'
import Blog from '../pages/Blog'
import Safety from '../pages/Safety'
import Product from '../pages/Product'
import BlogDetail from '../pages/BlogDetail'
import Welcome from '../pages/Welcome'
import Login from '../pages/Login'
import OauthRedirect from '../pages/Redirect'
import Register from '../pages/Register'
import Chat from '../pages/Chat'
import NotFond from '../pages/NotFound'
import DoctaAi from '../pages/DoctaAi'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import Article from '../pages/Article'
import EmailVerification from '../pages/EmailVerification'
import TermOfUse from '../pages/TermOfUse'
import SerivceTerms from '../pages/ServiceTerms'

// react 路由
const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	// {
	// 	path: '/about',
	// 	element: <About />,
	// },
	// {
	// 	path: '/blog',
	// 	element: <Blog />,
	// },
	// {
	// 	path: '/product',
	// 	element: <Product />
	// },
	// {
	// 	path: '/blog-detail/:id',
	// 	element: <BlogDetail />,
	// },
	// {
	// 	path: '/welcome',
	// 	element: <Welcome />,
	// },
	// {
	// 	path: '/login',
	// 	element: <Login />,
	// },
	// {
	// 	path: '/register',
	// 	element: <Register />,
	// },
	// {
	// 	path: '/chat',
	// 	element: <Chat />,
	// },
	// {
	// 	path: '/blog',
	// 	element: <Blog />,
	// },
	// {
	// 	path: '/safety',
	// 	element: <Safety />,
	// },
	// {
	// 	path: '/docta-ai',
	// 	element: <DoctaAi />,
	// },
	{
		path: '/article',
		element: <Article />,
	},
	{
		path: '/privacy-policy',
		element: <PrivacyPolicy />,
	},
	{
		path: '/term-of-use',
		element: <TermOfUse />,
	},
	{
		path: '/service-terms',
		element: <SerivceTerms />,
	},
	// {
	// 	path: '/email-verification',
	// 	element: <EmailVerification />,
	// },
	{
		path: '*', // 404 路由配置都写在最后，起到一个兜底的作用
		element: <NotFond />,
	},
])
export default router
